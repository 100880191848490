.container1 {
    max-width: 95%;
    width: 100%;
    margin: 20px auto;
    padding: 50px;
    background: radial-gradient(657px 657px at 2% 90%, #92b6fc 0, #dcf1f600 40%),
        radial-gradient(642px 642px at 82% 115%, #b4cdff 0, #dcf1f600 25%),
        linear-gradient(-73.25deg, #e1f3f7 7.43%, #9accd5 94.82%);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.header1 {
    text-align: left;
}

.header1 h1 {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
}

.header1 .level {
    font-size: 18px;
    margin: 5px 0;
}

.section1 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    row-gap: 15px;
}

.card1 {
    background-color: transparent;
    padding: 5px;
    border-radius: 10px;
    width: 20%;
    min-width: 180px;
    text-align: center;
    transition: all 0.3s ease;
}

.card1:hover {
    background-color: #dcf2f7;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.card1 .card1-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img-and-label {
    display: flex;
    padding: 12px;
}

.card1 img {
    width: 50px;
    margin-bottom: 10px;
}

.card1 .count {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
}

.card1 .label {
    font-size: 16px;
    margin: 0 0 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card1 .label i {
    width: 15px;
    margin-left: 5px;
}

.card1 a {
    font-size: 13px;
    font-weight: bold;
    color: #0078d4;
    text-decoration: none;
    transition: all 0.3s ease;
}

.card1 a i {
    font-weight: bold;
}

.card1 a:hover {
    font-size: 14px;
}

.streak-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.streak-toggle span {
    margin-right: 10px;
}

.button-group {
    text-align: center;
    margin: 30px 0;
}

.button-group a {
    margin: 0 10px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
}

.button-group a.active {
    background-color: #0078d4;
    padding: 15px 7px;
    color: #fff;
}

.custom-toggle {
    position: relative;
    width: 50px;
    height: 25px;
}

.custom-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 25px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2.5px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #0078d4;
}

input:checked + .slider:before {
    transform: translateX(17px);
}

.form-container,
.info-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.form-container {
    margin-right: 20px;
    flex-grow: 1;
}

.info-container {
    max-width: 300px;
}

.info-labels {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.info-label {
    margin-bottom: 10px;
    font-weight: bold;
}

.form-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.custom-upload-btn {
    display: inline-block;
    cursor: pointer;
    color: #fff;
    background-color: #007bff;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
}

.custom-upload-btn:hover {
    background-color: #0056b3;
}

.file-input {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.file-input input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
}

.alert {
    color: green;
    margin-top: 10px;
    text-align: center;
    display: none;
}

.form-control {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ced4da;
}

.form-control:focus {
    box-shadow: none;
    border-color: #007bff;
}

.input-group {
    display: flex;
    justify-content: space-between;
}

.input-group .form-control {
    flex: 1;
    margin-right: 10px;
}

.input-group .form-control:last-child {
    margin-right: 0;
}

.file-name {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 0.9rem;
}

.upload-progress {
    width: 100%;
    height: 25px;
    background-color: #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    display: none;
    position: relative;
}

.progress-bar {
    width: 0%;
    height: 100%;
    background-color: #28a745;
    border-radius: 5px;
    transition: width 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    overflow: hidden;
}

.progress-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
}

.upload-btn {
    display: flex;
    justify-content: center;
}

.upload-btn .upload-video {
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    background-color: #0d6efd;
    color: white;
    border-radius: 5px;
}

.sidebar {
    width: 200px;
    padding: 20px;
    background-color: #343a40;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.sidebar a {
    color: #fff;
    display: block;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
}

.sidebar a.logout {
    background-color: #dc3545;
}

.sidebar a.update-profile {
    background-color: #28a745;
}

.sidebar a:hover {
    background-color: #495057;
}

.header {
    display: flex;
    justify-content: space-between;
    background-color: #343a40;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    color: white;
}

.header div {
    flex: 1;
    text-align: center;
    margin: 0 10px;
    padding: 20px;
    border-radius: 10px;
}

.header .welcome {
    background-color: #007bff;
}

.header .videos-uploaded {
    background-color: #148e9f;
}

.header .earnings {
    background-color: #28a745;
}

.alert-danger {
    color: red;
    margin-top: 10px;
    text-align: center;
}
