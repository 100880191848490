a:hover {
    opacity: 0.8;
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

@-webkit-viewport {
    width: device-width;
}

@-moz-viewport {
    width: device-width;
}

@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

.left {
    float: left;
}

.right {
    float: right;
}

.txt_center {
    text-align: center;
}

.txt_lft {
    text-align: left;
}

.txt_rgt {
    text-align: right;
}

.block_left {
    display: block;
    float: left;
}

.block_right {
    display: block;
    float: right;
}

.bttn:hover {
    opacity: 0.8;
}

.yellow {
    background-color: #fdb735 !important;
}

.blue {
    background-color: #1aa0d9 !important;
}

.purple {
    background-color: #126aac !important;
}

.rf {
    display: inline-block;
    vertical-align: bottom;
    color: #199fd8;
    font-size: 22px;
    line-height: 10px;
}

.clear {
    clear: both;
}

sub,
sup {
    font-size: 55%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.2em;
}

.cf:after,
.container:after,
.mail_list:after {
    clear: both;
}

label,
select,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
    cursor: pointer;
}

html.styled-by-modal,
html.styled-by-modal body {
    height: 100%;
}

/*!ResetCSS-Ends*/
/*!UpgradeBrowserCSS-Ends*/
.upgradebrowser {
    z-index: 99999;
    position: relative;
    padding: 15px 20px;
    background: #1679c3;
    color: #fff;
}

.upgradebrowser p {
    padding-bottom: 0px;
}

.upgradebrowser strong {
    font-size: 24px;
    display: block;
    padding-bottom: 10px;
}

.upgradebrowser a {
    position: absolute;
    padding: 5px 20px;
    right: 20px;
    top: 25px;
    font-size: 14px;
    line-height: 20px;
    background: #fff;
    color: #1679c3;
}

/*!UpgradeBrowserCSS-Ends*/
.fs18 {
    font-size: 18px;
    font-family: "ATTAleckSans-Medium";
}

.mgn-top30 {
    margin-top: 30px;
    margin-bottom: 20px;
}

header {
    margin-block-start: auto;
}

.topright_wrap {
    float: center;
}

.grey_background {
    background-color: #f2f2f2;
}

.blue_background {
    background-color: #5499cb;
}

.grey_background .container,
.blue_background .container {
    padding: 30px 10px;
}

.toggle {
    display: none;
    width: 45px;
    height: 38px;
    position: relative;
}

ul,
ul li a {
    display: block;
}

.attiot_wrap {
    padding: 30px 0px 0px;
    overflow: auto;
    margin-bottom: 3%;
    display: table;
    width: 100%;
}

.attiot_wrap .left,
.attiot_wrap .right {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
    float: none;
}

.attiot_wrap .left img {
    width: 80%;
    display: block;
    margin: auto;
}

.attiot_box {
    max-width: 370px;
    margin: auto;
    border: 1px solid #959595;
    padding: 50px 28px 55px;
    border-radius: 2px;
    text-align: center;
    font-size: 15px;
}

.attiot_box p {
    padding: 15px 0px 20px;
    line-height: 24px;
    font-size: 16px;
}

.netwrk_readybanner {
    height: 400px;
    background: rgba(0, 0, 0, 0.5)
        url("https://testlabhub.com/images/submitrfq/network-ready-banner.jpg")
        no-repeat scroll center;
    background-position: center;
    background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
}

.netwrk_readybanner .container {
    position: relative;
    height: 100%;
}

.banner_wrap {
    color: #5a5a5a;
    float: right;
    max-width: 765px;
    width: 82%;
    height: 100%;
    padding: 10px;
    position: relative;
}

.banner_wrap .ban_table {
    display: table;
    height: 100%;
    position: relative;
    width: 100%;
}

.banner_wrap .ban_table .ban_content {
    display: table-cell;
    vertical-align: middle;
}

.banner_wrap .ban_content .fs20 {
    padding: 0px 0px 10px;
}

.banner_wrap .ban_content > div {
    padding: 25px 20px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.4);
    /*text-shadow:0px 0px 3px #fff;*/
}

.basicbanner .container {
    position: relative;
    height: 100%;
}

.banner_wrap {
    color: #5a5a5a;
    float: right;
    max-width: 765px;
    width: 82%;
    height: 100%;
    padding: 10px;
    position: relative;
}

.banner_wrap .ban_table {
    display: table;
    height: 100%;
    position: relative;
    width: 100%;
}

.banner_wrap .ban_table .ban_content {
    display: table-cell;
    vertical-align: middle;
}

.banner_wrap .ban_content .fs20 {
    padding: 0px 0px 10px;
}

.basicbanner .banner_wrap .ban_content > div {
    padding: 25px 20px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.6);
}

.basicbanner p,
.basicbanner h1 {
    color: #f2f2f2;
}

.modulePagebanner {
    height: 400px;
    background: rgba(0, 0, 0, 0.5)
        url("https://testlabhub.com/rfq/ModulePage_Banner.jpg") no-repeat scroll
        center;
    background-position: 68% center;
    background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
}

.modulePagebanner .container {
    position: relative;
    height: 100%;
}

.modulePagebanner .banner_wrap {
    color: #5a5a5a;
    float: right;
    max-width: 765px;
    width: 82%;
    height: 100%;
    padding: 10px;
    position: relative;
}

.modulePagebanner .banner_wrap .ban_content > div {
    padding: 25px 20px;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.6);
}

.modulePagebanner p,
.modulePagebanner h1 {
    color: #f2f2f2;
}

.devicePagebanner {
    height: 400px;
    background: rgba(0, 0, 0, 0.5)
        url("https://testlabhub.com/rfq/devicePageBanner.jpg") no-repeat scroll
        center;
    background-position: 60% center;
    background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
}

.devicePagebanner .container {
    position: relative;
    height: 100%;
}

.devicePagebanner .banner_wrap {
    color: #fff;
    float: left;
    max-width: 715px;
    width: 80%;
    height: 100%;
    padding: 10px;
    margin-left: 1%;
    position: relative;
}

.devicePagebanner p {
    font-size: 14px;
    line-height: 22px;
}

.devicePagebanner p.ban_icon_wrap {
    text-align: justify;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 5% 5% 0px;
}

.devicePagebanner p img {
    display: inline-block;
    vertical-align: middle;
    max-width: 56px;
    width: 15%;
    height: 100%;
}

.cnterbanner_wrap {
}

.contact_banner {
    height: 400px;
    background: rgba(0, 0, 0, 0.5)
        url("https://testlabhub.com/images/contact-banner.jpg") no-repeat scroll
        center top;
    background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
}

.contact_banner .cnterbanner_headings {
    max-width: 715px;
}

.cnterbanner_headings {
    height: 100%;
    margin: auto;
    max-width: 1050px;
    padding: 0 5px;
    position: relative;
}

.cnterbanner_headings div {
    display: table;
    height: 100%;
    position: relative;
    width: 100%;
}

.cnterbanner_headings div heading {
    display: table-cell;
    vertical-align: middle;
    color: #fff;
}

.cnterbanner_headings div heading {
    vertical-align: middle;
    text-align: center;
}

.cnterbanner_headings h1 {
    padding: 0px 0px 10px;
}

.cnterbanner_headings p {
    font-size: 16px;
    text-align: left;
}

.contact_banner.acclrtd {
    background: rgba(0, 0, 0, 0.5)
        url("https://testlabhub.com/images/acclrtd-contact-banner.jpg")
        no-repeat scroll center center;
    background-size: cover;
}

.networkCertified_banner .container {
    position: relative;
    height: 100%;
}

.banner_wrap {
    color: #5a5a5a;
    float: right;
    max-width: 765px;
    width: 82%;
    height: 100%;
    padding: 10px;
    position: relative;
}

.banner_wrap .ban_table {
    display: table;
    height: 100%;
    position: relative;
    width: 100%;
}

.banner_wrap .ban_table .ban_content {
    display: table-cell;
    vertical-align: middle;
}

.banner_wrap .ban_content .fs20 {
    padding: 0px 0px 10px;
}

.banner_wrap .ban_content > div {
    padding: 25px 20px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.4);
    /*text-shadow:0px 0px 3px #fff;*/
}

.zigzag_wrap {
    padding: 60px 0px;
}

.zigzag_wrap.iotkit {
    padding: 40px 0;
}

.zigzag_wrap img {
    margin: auto;
}

.zigzag_wrap .imgry_wrap,
.zigzag_wrap .info_wrap {
    display: table-cell;
    vertical-align: top;
}

.zigzag_wrap .imgry_wrap {
    width: 370px;
    text-align: center;
}

.zigzag_wrap .fs24 {
    padding: 0px 0px 20px;
}

.netwrk_ready .zigzag_wrap .imgry_wrap {
    width: 280px;
    padding-left: 150px;
}

/*.zigzag_wrap.abtiot_wrap{padding-top:0px;margin-top:-5px;}*/
.zigzag_wrap.abtiot_wrap img {
    width: 95%;
}

.zigzag_wrap.wireles_comnctn_wrap .imgry_wrap {
    width: 450px;
}

.zigzag_wrap.module_perfm .imgry_wrap {
    width: 400px;
}

.zigzag_wrap.module_perfm img {
    width: 100%;
}

.zigzag_wrap.wireles_comnctn_wrap img {
    width: 95%;
}

/* .why_certify_section,
.take_advntg_section{padding:0px 0px 10px;} */
.why_certify_section p {
    padding-bottom: 15px;
}

.images_section .imgtwo_col {
    margin: 0px -7px;
    overflow: auto;
}

.images_section .imgtwo_col .colmn {
    padding: 14px 7px;
    float: left;
    width: 50%;
}

.devicebuild_wrap .center_cntnt_wrap,
.devicebuy_wrap .center_cntnt_wrap {
    display: table-cell;
    vertical-align: top;
}

.center_cntnt_wrap {
    display: table-cell;
    vertical-align: middle;
}

.top_cntnt_wrap {
    padding-bottom: 100px;
}

.images_section .fs18 {
    padding-bottom: 15px;
    position: relative;
}

.images_section .techimg_wrap .fs18:after {
    background-position: -37px 0px;
}

.devices_wrap .images_section .fs18,
.devices_wrap .images_section .fs24 {
    text-align: center;
}

.images_section .devicebuy_wrap .fs18:after,
.images_section .devicebuild_wrap .fs18:after,
.images_section .devicewhen_wrap .fs18:after {
    background-position: -37px 0px;
    background-image: none;
}

.why_certify_section .images_section p {
    padding-bottom: 0px;
    font-size: 14px;
    display: block;
    max-width: 400px;
    margin: auto;
    text-align: left;
}

.why_certify_section .fs24 {
    padding: 20px 0px;
}

.gt_crtfied_wrap ul.widicons_list {
    padding-top: 2%;
}

ul.widicons_list > li strong {
    font-size: 18px;
    font-family: "ATTAleckSans-Medium";
    display: block;
    font-weight: normal;
}

ul.widicons_list > li {
    padding: 20px 0px 10px 95px;
    position: relative;
}

ul.widicons_list > li:before {
    content: "";
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    left: 0;
    background-image: url("https://testlabhub.com/images/submitrfq/get-certified-listthumbs.png");
    background-repeat: no-repeat;
    background-position: 0 0;
}

ul.widicons_list > li.checkmark_icon:before {
    background-position: 0px -1325px;
}

ul.widicons_list > li.device_icon:before {
    background-position: -5px 5px;
}

ul.widicons_list > li.module_icon:before {
    background-position: 2px -363px;
}

ul.widicons_list > li.chipset_icon:before {
    background-position: 0px -585px;
}

ul.widicons_list > li.aprvModule_icon:before {
    background-position: 0px -365px;
}

ul.widicons_list > li.PtcrbCrtfied_icon:before {
    background-position: 0px -475px;
}

ul.widicons_list > li.CntrlCenter_icon:before {
    background-position: 0px -280px;
}

ul.widicons_list > li.OdisCmplnc_icon:before {
    background-position: 0px -695px;
}

ul.widicons_list > li.Utac_icon:before {
    background-position: 0px -803px;
}

ul.widicons_list > li.Csv_icon:before {
    background-position: 0px -910px;
}

ul.widicons_list > li.DeviceCertf_icon:before {
    background-position: 0px -1096px;
}

ul.widicons_list > li.netrokpro_icon:before {
    background-position: 0px -110px;
}

ul.widicons_list > li.module_icon1:before {
    background-image: url("https://testlabhub.com/rfq/icon/mediastackL.svg");
}

ul.widicons_list > li.module_icon2:before {
    background-image: url("https://testlabhub.com/rfq/icon/searchfindL.svg");
}

ul.widicons_list > li.module_icon3:before {
    background-image: url("https://testlabhub.com/rfq/icon/fastforwardL.svg");
}

ul.widicons_list > li.module_icon4:before {
    background-image: url("https://testlabhub.com/rfq/icon/selectL.svg");
}

ul.widicons_list > li.device_icon1:before {
    background-position: 0px -915px;
}

ul.widicons_list > li.device_icon2:before {
    background-position: 0px -1325px;
}

ul.widicons_list > li.price_tag:before {
    background-position: 0px -1780px;
    background-size: 95%;
}

ul.widicons_list > li.battery:before {
    background-position: 0px -1809px;
}

ul.widicons_list > li.building:before {
    background-position: 0px -1746px;
}

ul.widicons_list > li.LTE:before {
    background-position: 0px -1215px;
}

ul.widicons_list > li.LTE_net:before {
    background-position: 0px -2036px;
}

ul.widicons_list > li.roadmap:before {
    background-position: 0px -2119px;
}

.withouticon ul.widicons_list {
    padding-top: 2%;
}

.withouticon ul.widicons_list > li {
    padding: 20px 0px 10px 0px;
    position: relative;
    text-align: left;
}

.withouticon ul.widicons_list > li:before {
    background-image: none;
    background-image: none;
    width: 0;
    height: 0;
    margin-bottom: 0;
}

ul.widicons_list.wid_or {
}

ul.widicons_list.wid_or li {
    padding: 36px 0px 34px 95px;
    border-bottom: 1px solid #d2d2d2;
}

ul.widicons_list.wid_or li:after {
    content: "OR";
    font-size: 28px;
    line-height: 34px;
    width: 60px;
    height: 28px;
    text-align: center;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: -14px;
    background-color: #fff;
}

ul.widicons_list.wid_or li:last-child {
    border: 0px;
}

ul.widicons_list.wid_or li:last-child:after {
    display: none;
}

ul.widicons_list.no_icon > li:before {
    display: none;
}

.securityIcon_wrap {
    display: block;
    padding: 10px 0px 30px 65px;
    position: relative;
}

.securityIcon_wrap:before {
    content: "";
    display: block;
    position: absolute;
    width: 40px;
    height: 45px;
    left: 0;
    background-image: url("https://testlabhub.com/images/submitrfq/get-certified-listthumbs.png");
    background-repeat: no-repeat;
    background-position: -7px -1012px;
}

ul.points {
    margin-left: 100px;
    padding: 15px 0px;
}

ul.points li {
    padding: 5px 0px 5px 20px;
    background-image: url("https://testlabhub.com/rfq/icon/dot.jpg");
    background-position: 0px 12px;
    background-repeat: no-repeat;
}

.GetStartedMain {
    margin: auto;
    max-width: 1410px;
    position: relative;
    overflow: hidden;
    padding: 0px 3%;
}

.GetStarted_wrap .fs24 {
    padding: 20px 0px 10px;
    text-align: center;
}

.GetStarted_wrap .GetStartedInfo {
    width: 67%;
    float: left;
    padding: 5% 0 5% 3%;
}

.GetStarted_wrap .GetStartedInfo p {
    padding: 10px 0px;
}

.GetStarted_wrap .GetStartedPic {
    width: 35%;
    float: right;
    margin: 8% -5% -15px 0;
}

.GetStarted_wrap .GetStartedPic img {
    max-width: 88%;
}

.blue_strip_wrap {
    max-width: 700px;
    margin: auto;
}

.blue_strip {
    padding: 30px 20px 30px;
    background-color: #fdb735 !important;
    text-align: center;
    /* color: #fff; */
    font-size: 24px;
    line-height: 50px;
}

.blue_strip strong {
    font-weight: normal;
    font-size: 24px;
    font-family: "ATTAleckSans-Bold";
}

.blue_strip p {
    font-weight: normal;
    font-size: 16px;
    margin-top: 5px;
}

.blue_strip a {
    color: #fff;
    text-decoration: underline;
}

.blue_strip .bheadtwocol {
    float: left;
    width: 50%;
    text-align: left;
    padding-right: 70px;
    border-right: 1px solid #fff;
}

.blue_strip .bheadtwocol:last-child {
    padding-right: 0px;
    padding-left: 70px;
    border-right: 0;
}

.blue_strip .bheadtwocol h3 {
    margin-bottom: 15px;
}

.bheadtwocol_wrap {
    max-width: 1024px;
    margin: auto;
}

.blue_strip .bheadThreeCols a[target="_blank"] {
    padding-right: 0;
}

.blue_strip .bheadThreeCols .fs24 {
    font-size: 20px;
}

.blue_strip .bheadThreeCols p {
    font-size: 14px;
    line-height: normal;
}

.blue_strip .bheadThreeCols .bheadCol {
    display: table-cell;
    text-align: left;
    width: 33.33%;
    padding: 5px 5%;
    border-left: 1px solid #fff;
}

.blue_strip .bheadThreeCols .bheadCol:first-child {
    padding-left: 0px;
    border-left: 0px;
}

.blue_strip .bheadThreeCols .bheadCol:last-child {
    padding-right: 0px;
}

.announcements_wrap {
    position: relative;
}

.announcements_wrap .fs22 {
    background-color: #fdb735;
    color: #ffffff;
    text-align: center;
    padding: 5px 10px 1px;
    font-size: 24px;
    font-family: "ATTAleckSans-Regular";
}

ul.anncment_list {
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    display: table;
    width: 92%;
    margin: auto;
}

/*#54 - Scrolling Tiles*/
ul.anncment_list li {
    display: inline-block;
    vertical-align: top;
    border: 3px solid #fff;
    width: 25%;
    float: left;
    padding: 30px 20px;
}

ul.anncment_list li a {
    display: block;
    max-width: 265px;
    margin: auto;
}

ul.anncment_list li a figure img {
    display: block;
    width: 100%;
}

ul.anncment_list li a .fs18 {
    padding: 8px 0px 3px;
    text-align: center;
    color: #5a5a5a;
    position: relative;
}

ul.anncment_list li a p {
    padding: 0px 0px 5px;
    display: block;
    /*font-size:15px;*/
    line-height: 20px;
}

.contact_wrap {
    max-width: 715px;
}

.contact_wrap .spacer {
    padding: 7% 0px;
}

.thanks_msg {
    text-align: center;
    font-size: 16px;
    line-height: normal;
}

.thanks_msg strong {
    font-weight: normal;
    font-family: "ATTAleckSans-Bold";
    color: ;
    font-size: 50px;
    display: block;
}

.form_wrap {
    padding: 10% 0px 80px;
}

.form_wrap .row {
    overflow: auto;
    margin: 0px -10px;
}

.form_wrap .row .field_wrap {
    padding: 0px 0px 15px;
}

.lbl_wrap {
}

.input_wrap {
}

.col-1 {
    width: 8.33%;
}

.col-2 {
    width: 16.66%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33%;
}

.col-5 {
    width: 41.66%;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 58.33%;
}

.col-8 {
    width: 66.66%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 83.33%;
}

.col-11 {
    width: 91.66%;
}

.col-12 {
    width: 100%;
}

ul.widicons_list.twocol {
    margin: 10px 0px 60px !important;
}

.container .blue_strip {
    left: 0;
    right: 0;
    padding: 30px 20px 26px;
    background-color: #126aac;
    text-align: center;
    color: #fff;
    font-size: 24px;
    line-height: 28px;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
}

.devices_wrap {
    padding: 60px 10px;
}

.devices_wrap .fs28 {
    padding-left: 95px;
}

.devices_wrap .fs24 {
    padding: 0px 0px 20px;
}

ul.devices_list {
    margin-top: 20px;
}

ul.devices_list > li {
    padding: 25px 0px 25px 95px;
    position: relative;
}

ul.devices_list > li:before {
    content: "";
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    left: 0;
    background-image: url(https://testlabhub.com/images/get-certified-listthumbs.png);
    background-repeat: no-repeat;
    background-position: 0 -1425px;
}

ul.devices_list > li strong {
    display: block;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    font-family: "ATTAleckSans-Medium";
    padding: 13px 0px 6px;
}

ul.devices_list > li:nth-child(2):before {
    background-position: 0 -1505px;
}

ul.devices_list > li:nth-child(3):before {
    background-position: 0 -1585px;
}

ul.devices_list > li:nth-child(4):before {
    background-position: 0 -1664px;
}

.devices_wrap ul {
    margin-left: 10%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.devices_wrap ul li {
    list-style: disc;
    padding: 1px 0px;
}

.twinbx_wrap {
    overflow: auto;
    margin: 15px 0px 50px;
}

.bx_wrap {
    width: 47%;
    padding: 30px 5% 90px;
    border: 1px solid #d2d2d2;
    border-radius: 8px;
    float: left;
    position: relative;
    min-height: 650px;
}

.twinbx_wrap .bx_wrap:last-child {
    float: right;
}

.bx_wrap > span {
    display: block;
    margin: auto;
    width: 130px;
    height: 130px;
    border-radius: 130px;
    background-color: #71c5e8;
    color: #fff;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    padding: 45px 0px;
    margin-bottom: 30px;
}

.bx_wrap .bttn {
    width: 272px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -136px;
}

.bx_wrap ul,
.faq_wrap ul {
    margin-left: 10%;
}

.faq_wrap ul {
    margin-bottom: 20px;
}

.bx_wrap ul li,
.faq_wrap ul li {
    list-style: disc;
    padding: 1px 0px;
}

.faqhead_wrap {
    padding: 5% 0px 0px;
}

.faqhead_wrap .fs24 {
    color: #126aac;
    text-align: center;
    margin-bottom: 10px;
}

.search_wrap {
    background-color: #fff;
    border: 1px #ccc solid;
    border-radius: 6px;
    max-width: 620px;
    margin: 3% auto 0px;
    position: relative;
    padding: 0px 40px 0px 10px;
}

.search_wrap input {
    color: #595959;
    background: none;
    border: 0;
    font-size: inherit;
    font-family: inherit;
}

.search_wrap input[type="search"] {
    box-sizing: content-box;
    height: 40px;
    width: 100%;
    -webkit-appearance: none;
    text-align: center;
}

.search_wrap input[type="button"],
.search_wrap input[type="submit"] {
    position: absolute;
    height: 100%;
    width: 40px;
    right: 0;
    top: 0;
    cursor: pointer;
    background-image: url(https://testlabhub.com/images/search-icon.png);
    background-repeat: no-repeat;
    background-position: center;
}

.search_wrap input[type="button"]:hover,
.search_wrap input[type="submit"]:hover {
    background-color: #ccc;
}

.clearbtn {
    margin-left: 20px;
    display: inline-block;
    font-weight: normal;
    font-family: "ATTAleckSans-Medium";
    font-size: 18px;
    color: #126aac;
    text-decoration: underline;
    cursor: pointer;
}

.faqbdy_wrap {
    margin: 50px 0px 60px;
}

.faq_wrap {
    padding: 20px 15px 22px;
}

.faq_wrap p,
.faq_wrap > strong {
    padding-bottom: 15px;
    display: block;
    position: relative;
}

.faq_wrap > strong {
    padding: 0px 5px 15px 25px;
    font-weight: normal;
    font-family: "ATTAleckSans-Medium";
    font-size: 18px;
    color: #126aac;
    text-decoration: underline;
    cursor: pointer;
}

.faq_wrap > strong:before {
    position: absolute;
    left: 0px;
    top: 6px;
    content: "+";
    width: 9px;
    height: 9px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 25px;
    line-height: 12px;
}

.faq_wrap > strong.expanded:before {
    content: "-";
    line-height: 6px;
    left: 2px;
}

.faq_wrap .ans {
    padding: 0px 5px 0px 25px;
}

.faq_wrap:nth-child(odd) {
    background-color: #f2f2f2;
}

.pagination_wrap {
    text-align: right;
}

.cd-pagination {
    display: inline-block;
    max-width: 480px;
    width: 100%;
    margin: 1em 0 5em;
    text-align: center;
    position: relative;
}

.cd-pagination li {
    display: inline-block;
    margin: 0 0.2em;
}

.cd-pagination a {
    border: 1px solid #d2d2d2;
    border-radius: 0.25em;
}

.cd-pagination .current {
    background-color: #126aac;
    border-color: #64a281;
    color: #ffffff;
    pointer-events: none;
}

.cd-pagination a,
.cd-pagination span {
    color: #595959;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.4em 0.8em 0.3em;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
}

.no-touch .cd-pagination a:hover {
    background-color: #f2f2f2;
}

.cd-pagination a:active {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
}

.cd-pagination a.disabled {
    color: rgba(46, 64, 87, 0.4);
    pointer-events: none;
}

.cd-pagination.move-buttons .button:first-of-type {
    float: left;
}

.cd-pagination.move-buttons .button:last-of-type {
    float: right;
}

/* new footer */
[class*="icon-"] {
    display: inline-block;
    font-style: normal;
    font-size: 20px;
    font-weight: normal;
    font-variant: normal;
    height: 1em;
    margin-right: 7px;
    text-transform: none;
    line-height: 1;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: none;
    position: relative;
    speak: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    width: 1em;
}

[class*="icon-"]:before,
[class*="icon-"]:after {
    box-sizing: border-box;
    display: inline-block;
    font-size: 1em;
    height: 1em;
    position: relative;
    top: 0;
    left: 0;
    vertical-align: middle;
    width: 1em;
}

/* .legalnbadge_wrap{padding:30px 150px 30px 0px;position:relative;} /* CR #95 - New link addition in footer */

.footer {
    font-size: 12px;
    line-height: 16px;
    border-top: 4px solid #067ab4;
    padding-top: 30px;
    background-color: #131313;
    color: #959595;
}

.footer h3 {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 20px;
}

.footer a:hover {
    text-decoration: none;
}

.footer a:focus {
    outline: 1px dotted white;
    text-decoration: none;
}

.footer-legal {
    font-size: 12px;
    line-height: 16px;
    color: #959595;
}

/* CR #95 - New link addition in footer */

.social_wrap a i[class*="icon-social"],
.social_wrap a i[class*="icon-social"]::before {
    font-size: 40px;
    width: 40px;
    height: 40px;
    color: #fff;
    margin-right: 0px;
    font-style: normal;
}

.footcnnect_wrap .bsns_cmntilink i[class*="icon-bubble"] {
    display: inline-block;
    vertical-align: bottom;
}

.footcnnect_wrap .bsns_cmntilink i[class*="icon-bubble"],
.footcnnect_wrap .bsns_cmntilink i[class*="icon-bubble"]::before {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
}

.cjc_wrap {
    padding: 30px 20px;
}

.cjc_wrap .text_block {
    max-width: 800px;
    margin: auto;
    text-align: center;
    padding: 50px 0;
}

.cjc_wrap .text_block h1 {
    margin-bottom: 35px;
}

.cjc_chart {
    position: relative;
    padding: 50px 0;
}

.cjc_chart h2 {
    color: #0275ad;
    font-size: 20px;
    font-family: "ATTAleckSans-Medium";
}

.cjc_chart h2 span {
    color: #0275ad;
    font-size: 32px;
    display: block;
}

.cjc_chart .img {
    max-width: 792px;
    margin: auto;
}

.cjc_chart .img img {
    width: 100%;
}

.coming_wrap {
    padding: 30px 20px;
    display: table;
    width: 100%;
}

.coming_wrap .text_block {
    text-align: center;
    padding-top: 10%;
    padding-bottom: 30%;
}

.network-chart {
    max-width: 1094px;
    margin: auto;
    padding: 0;
}

.network-chart img {
    width: 100%;
    height: auto;
}

.m2xflow {
    overflow: hidden;
}

.device_twocol {
}

.device_twocol li {
    position: relative;
    padding: 30px 0 10px 0;
}

.device_twocol li img {
    margin-bottom: 25px;
}

.zigzag_wrap.module_perfm h3 {
    padding: 20px 0;
}

.widicons_list.extrapading p {
    padding-top: 20px;
}

.widicons_list.extrapading li {
    margin-bottom: 20px;
}

.widicons_list.extrapading {
    overflow: hidden;
    margin: 20px 0;
}

.zigzag_wrap.module_perfm p {
    margin-bottom: 10px;
}

.mail_list {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
    font-size: 0;
}

.mail_list li {
    width: 33.33%;
    padding: 0 15px;
    display: inline-block;
    margin-bottom: 30px;
    font-size: 14px;
    vertical-align: top;
}

.mail_list li span {
    display: block;
    color: #126aac;
}

.mail_list li p {
    margin-bottom: 0 !important;
}

/* .footer.fixed{ position:absolute; width:100%; bottom:0;} */

/* device cerfified */
.devicecertbdy_wrap {
    margin: 50px 0px 60px;
}

.icon-close::before {
    color: #0568ae;
    display: inline-block;
    font-family: "icoPrimary" !important;
    font-style: normal;
    font-size: 20px;
    font-weight: normal;
    font-variant: normal;
    height: 1em;
    margin-right: 7px;
    text-transform: none;
    line-height: 1;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: none;
    position: relative;
    speak: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    width: 1em;
}

[class*="icon-"]:before,
[class*="icon-"]:after {
    box-sizing: border-box;
    display: inline-block;
    font-size: 1em;
    height: 1em;
    position: relative;
    top: 0;
    left: 0;
    vertical-align: middle;
    width: 1em;
}

.icon-close:before,
.close:before,
.reset-field:before {
    content: "\e922";
}

.corner-button {
    border-color: #f2f2f2 #f2f2f2 transparent transparent;
    border-style: solid;
    border-width: 24px;
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
}

.corner-button .icon-close::before {
    color: #0568ae;
    display: block;
    font-size: 20px;
    height: auto;
    position: absolute;
    right: -4px;
    top: 4px;
    width: auto;
}

.corner-button .icon-close {
    float: none;
    height: 48px;
    margin: 0;
    position: absolute;
    right: -32px;
    top: -24px;
    width: 48px;
    cursor: pointer;
}

button.icon-close {
    -moz-appearance: none;
    border: 0 none;
}

.reset-field,
.icon-close {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    display: inline-block;
    float: left;
    height: 34px;
    overflow: hidden;
    padding: 0;
    width: 34px;
}

.modalwrapper.modal-docked .modal {
    height: auto;
}

/* end device cerfified */
.completentwk_wrap {
    padding: 60px 0px;
}

.completentwk_wrap .devicewrap {
    float: left;
    width: 35%;
}

.completentwk_wrap .devicewrap .diagram {
    max-width: 355px;
}

.completentwk_wrap .devicewrap .diagram .cell {
    width: 150px;
    margin-top: 30px;
}

.completentwk_wrap .devicewrap .diagram .cell div {
    padding: 15px 5px;
    text-align: center;
    background-color: #f6f6f6;
    margin-bottom: 3px;
    position: relative;
}

.completentwk_wrap .devicewrap .diagram .cell div:first-child {
    background-color: #1f70a1;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
}

.completentwk_wrap .devicewrap .diagram .cell div:nth-child(2) {
    text-align: left;
    padding-left: 47px;
    min-height: 88px;
}

.completentwk_wrap .devicewrap .diagram .cell div:nth-child(2):before {
    content: "";
    position: absolute;
    left: 5px;
    width: 35px;
    height: 33px;
    background-image: url(https://testlabhub.com/images/get-certified-listthumbs.png);
    background-repeat: no-repeat;
    background-position: -15px -1980px;
}

.completentwk_wrap .devicewrap .diagram .cell div.arrow {
    background-color: #fff;
    height: 200px;
    position: relative;
    padding-top: 60px;
}

.completentwk_wrap .devicewrap .diagram .cell div.arrow:before {
    content: "";
    position: absolute;
    background-image: url(https://testlabhub.com/images/refresh1.png);
    background-repeat: no-repeat;
    width: 109px;
    height: 55px;
    left: 15px;
    top: 5px;
}

.completentwk_wrap .devicewrap .diagram .cell div.arrow:after {
    content: "";
    position: absolute;
    background-image: url(https://testlabhub.com/images/refresh2.png);
    background-repeat: no-repeat;
    width: 109px;
    height: 65px;
    left: 30px;
    bottom: 0;
}

.completentwk_wrap .devicewrap .diagram .cell.full {
    width: 100%;
    float: left;
    margin-top: 0;
}

.completentwk_wrap .devicewrap .diagram .cell.full div:nth-child(2) {
    padding-left: inherit;
    text-align: center;
    min-height: auto;
}

.completentwk_wrap .devicewrap .diagram .cell.full div:nth-child(2):before {
    background-image: none;
}

.completentwk_wrap .runtest {
    float: right;
    width: 55%;
}

.completentwk_wrap .runtest h3 {
    margin-bottom: 10px;
}

.panelhead_wrap {
    background-color: #626262;
    padding: 10px 20px;
}

.panelhead_wrap h2 {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
}

.panelbody_wrap {
    background-color: #f6f6f6;
    padding: 20px;
}

.panelbody_wrap p {
    margin-bottom: 15px;
}

.panelbody_wrap hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid #fff;
    margin: 1em -20px;
    padding: 0;
}

.sms_wrap {
    background-color: #626262;
    position: relative;
    color: #fff;
    font-size: 16px;
    width: 300px;
    padding-left: 70px;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto;
}

.sms_wrap:before {
    background-color: #009fdb;
    background-image: url("https://testlabhub.com/images/submitrfq/sms.png");
    background-repeat: no-repeat;
    background-position: center center;
    width: 67px;
    height: 67px;
    border-radius: 50%;
    content: "";
    position: absolute;
    left: -8px;
    top: -15px;
}

/* search*/

[class*="icon-controls-"] {
    font-family: "icoControls" !important;
}

.icon-controls-magnifyingglass:before {
    content: "\e929";
}

.global-search {
    float: right;
    margin-top: 12px;
    width: 150px;
}

.field-group {
    position: relative;
    display: block;
}

.global-search input {
    background-color: #ffffff;
    border: 1px solid #959595;
    box-shadow: 2px 3px 2px -2px rgba(0, 0, 0, 0.08) inset;
    color: #191919;
    transition: border 0.3s linear 0s;
    border-radius: 6px;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    width: 100%;
}

.global-search input[type="search"] {
    text-overflow: ellipsis;
    -webkit-appearance: none !important;
    padding: 6px 7px 6px 15px;
    padding-right: 40px;
}

.global-search input:focus {
    border: 1px solid #0568ae;
}

@-moz-document url-prefix() {
    .global-search input[type="search"] {
        padding: 8px 7px 6px 15px;
    }
}

.btn-search[class*="btn"] {
    background-color: transparent;
    border: none;
    height: 2rem;
    margin-left: 0;
    margin-top: 0;
    min-width: 2rem !important;
    outline-offset: 0;
    padding: 0 !important;
    position: absolute;
    right: 0;
    top: 0.15rem;
    border-radius: 0 1.5rem 1.5rem 0;
}

.btn-search[class*="btn"] > i {
    color: #767676;
}

.btn-search[class*="btn"]:focus,
.btn-search[class*="btn"]:active {
    outline: 1px dotted #0568ae;
}

input[type="search"]:focus ~ .btn-search > i,
.btn-search:focus > i {
    color: #0568ae;
}

input[type="search"]::-webkit-input-placeholder {
    color: #767676;
    font-style: italic;
    opacity: 1;
}

input[type="search"]::-moz-placeholder {
    color: #767676;
    font-style: italic;
    opacity: 1;
}

input[type="search"]::-ms-input-placeholder {
    color: #767676;
    font-style: italic;
    opacity: 1;
}

input[type="search"]::placeholder {
    color: #767676;
    font-style: italic;
    opacity: 1;
}

input[type="search"]:focus {
    z-index: 1000;
}

input[type="search"]::-webkit-search-cancel-button {
    display: none !important;
}

input[type="search"]::-ms-clear {
    display: none !important;
}

.icon-spinner {
    animation: 1s linear 0s normal none infinite running spinner;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2036%2036%22%3E%0A%20%20%3Cpath%20fill%3D%22%230569AE%22%20d%3D%22M18%2036c-2.7%200-4.8-.6-6.2-1C8%2033.6%205.4%2031.1%204%2029.3%202.7%2027.6.9%2024.7.3%2020.8c-.3-2.1-.5-5.4.9-9.1C3%206.8%206.4%204.1%208.3%202.8%2011.8.5%2015.4%200%2017.8%200l.1%204.3c-1.8%200-4.6.4-7.3%202.2-1.4.9-4%203-5.4%206.7-1%202.8-.9%205.3-.7%206.9.5%203%201.8%205.1%202.9%206.4%201.1%201.4%203%203.2%206%204.3%201.5.5%204.6%201.3%208.3.3%203-.8%205-2.5%206.2-3.7%201.4-1.4%202.4-3.2%203.1-5%20.4-1.3.9-3.3.6-5.8-.5-4.2-2.8-7.1-4.2-8.5L30.5%205c1.8%201.8%204.8%205.6%205.4%2011.1.3%203.2-.3%205.9-.9%207.6-.8%202.4-2.2%204.8-4%206.7-1.5%201.6-4.2%203.8-8.1%204.9-1.8.5-3.4.7-4.9.7z%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M17.8%202.2c1.8%200%204.9.3%208.2%202.2%201.2.7%202.2%201.5%203%202.3%22%2F%3E%0A%20%20%3Cpath%20fill%3D%22%23F2F2F3%22%20d%3D%22M27.5%208.1c-.8-.8-1.7-1.4-2.6-1.9-2.1-1.2-4.5-1.9-7.1-1.9V0c3.3%200%206.5.9%209.3%202.4%201.2.8%202.3%201.6%203.4%202.6l-3%203.1z%22%2F%3E%0A%3C%2Fsvg%3E");
    height: 50px;
    width: 50px;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

.searchbdy_wrap {
    margin: 50px 0px 60px;
}

.search_wrap_page {
    padding: 10px 0;
}

.search_wrap_page p,
.search_wrap_page > strong {
    padding-bottom: 15px;
    display: block;
    position: relative;
}

.search_wrap_page > strong {
    padding: 0px 5px 15px 0px;
    font-weight: normal;
    font-family: "ATTAleckSans-Medium";
    font-size: 18px;
    color: #126aac;
    text-decoration: underline;
    cursor: pointer;
}

/* .leftnav ul li a{  display: block;line-height: normal; margin: 5px -9px; padding: 6px 35px 7px 9px; outline-offset: 0; position: relative; text-decoration:none;}
.leftnav ul li a.active{ color:#191919 !important} */
.content_right_wrap {
    padding-left: 330px;
    width: 100%;
    display: block;
}

/* ioT accelerator program*/

.iotaccelerator_wrap {
    padding: 60px 0px;
}

.iotaccePagebanner {
    height: 400px;
    background: rgba(0, 0, 0, 0.5)
        url("https://testlabhub.com/images/iot-accelerator-banner.jpg")
        no-repeat scroll center;
    background-position: 85% center;
    background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
}

.iotaccePagebanner .container {
    position: relative;
    height: 100%;
}

.iotaccePagebanner .banner_wrap {
    color: #fff;
    float: left;
    max-width: 768px;
    width: 98%;
    height: 100%;
    padding: 10px;
    margin: 0 1%;
    position: relative;
}

.iotaccePagebanner p {
    font-size: 14px;
    line-height: 20px;
}

.iotaccePagebanner p a {
    color: #fff;
    text-decoration: underline;
}

.module_compare_wrap {
    margin: 50px 0;
}

.module_compare_wrap h2 {
    background-color: #067fba;
    color: #fff;
    padding: 10px;
    font-size: 24px;
    line-height: 30px;
}

.module_compare_grid {
    width: 100%;
    overflow: auto;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

.module_compare_grid table {
    width: 100%;
    font-size: 14px;
    margin-bottom: 0;
    border: 0;
}

.module_compare_grid table th {
    background-color: #6b6c6b;
    color: #fff;
    font-family: "ATTAleckSans-Regular";
    padding: 2px;
    vertical-align: middle;
    text-align: center;
}

.module_compare_grid table td {
    border: 0;
    padding: 3px;
    vertical-align: middle;
    text-align: center;
}

.module_compare_grid table tr {
    border-bottom: 1px solid #cccccc;
}

.module_compare_grid table tr:last-child {
    border-bottom: 0;
}

.module_compare_grid table td:first-child {
    padding: 2px 2px;
}

.module_compare_grid table td:last-child {
    padding: 2px 2px;
}

.module_compare_grid table td:nth-child(even) {
    background-color: #ededed;
}

.module_compare_grid table td p {
    line-height: 12px;
    text-align: center;
    font-size: 11px;
    margin-top: 5px;
}

.module_compare_grid table td a {
    font-size: 14px;
    font-family: "ATTAleckSans-Bold";
    color: #2c8dba;
    text-decoration: none;
}

.aclrtBttns {
    padding-bottom: 50px;
}

.aclrtBttns a {
    font-size: 20px;
    font-family: "ATTAleckSans-Medium";
    color: #fff;
    padding: 20px 3% 18px;
    background-color: #4bb0dc;
    text-align: center;
    margin-right: 30px;
    text-decoration: none;
    display: inline-block;
    border-radius: 85px;
    max-width: 280px;
}

.aclrtBttns a:last-of-type {
    background-color: #6b6c6b;
}

.threeblock_wrap {
    width: 100%;
}

.threeblock_wrap .row {
    margin: 0 -15px;
}

.threeblock_wrap .column {
    width: 33.33%;
    float: left;
    line-height: 1;
    padding: 10px 10px;
}

.threeblock_wrap .column div {
    position: relative;
}

.threeblock_wrap .column div h4 {
    position: absolute;
    padding: 10px 0;
    bottom: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    font-size: 17px;
    font-family: "ATTAleckSans-Bold";
    line-height: 20px;
    color: #fff;
    text-align: center;
}

.threeblock_wrap .column div img {
    width: 100%;
}

.broadband_wrap {
    margin: 50px 0;
}

.broadband_wrap h2 {
    color: #029fd5;
    padding: 10px 0;
    font-size: 19px;
    font-family: "ATTAleckSans-Bold";
    line-height: 30px;
}

.broadband_wrap_grid {
    width: 100%;
    overflow: auto;
}

.broadband_wrap_grid table {
    width: 100%;
    font-size: 12px;
    border: 0;
}

.broadband_wrap_grid table th {
    border: 1px solid #fff;
    background-color: #6b6c6b;
    font-size: 25px;
    line-height: 40px;
    color: #fff;
    font-family: "ATTAleckSans-Regular";
    padding: 5px;
    vertical-align: middle;
    text-align: center;
}

.broadband_wrap_grid table tr td {
    border: 0;
    padding: 3px;
    vertical-align: middle;
    text-align: center;
    border-right: 1px solid #fff;
}

.broadband_wrap_grid table tr td:first-child {
    background-color: #05b2e0;
    border-bottom: 1px solid #fff;
    padding: 15px 0;
    font-size: 20px;
    color: #fff;
}

.broadband_wrap_grid table tr td:nth-child(2) {
    background-color: #067fba;
    padding: 15px 0;
    font-family: "ATTAleckSans-Medium";
    font-size: 19px;
    color: #fff;
}

.broadband_wrap_grid table tr td p {
    font-size: 11px;
    line-height: 12px;
    color: #444;
}

.broadband_wrap_grid table tr td.none {
    background-color: transparent;
    border: 0;
}

.broadband_wrap_grid .device_list {
    display: table;
    width: 100%;
    margin-bottom: 10px;
}

.broadband_wrap_grid .device_list:last-child {
    margin-bottom: 0;
}

.broadband_wrap_grid .device_list div {
    display: table-cell;
    text-align: left;
    vertical-align: top;
    border-bottom: 2px solid #eee;
    padding: 10px 0;
}

.broadband_wrap_grid .device_list div:first-child {
    width: 130px;
    text-align: center;
    border: 0;
}

.broadband_wrap_grid .device_list div li {
    display: inline-block;
    background-image: url("https://testlabhub.com/images/bluedot.jpg");
    background-repeat: no-repeat;
    background-position: 0 7px;
    font-size: 19px;
    line-height: 28px;
    color: #333333;
    font-family: "ATTAleckSans-Medium";
    padding: 0 22px;
    position: relative;
}

.tierWrap {
    margin: 50px 0px;
}

.tierWrap p:first-of-type {
    margin-bottom: 20px;
}

.tierGrid {
    width: 100%;
    overflow: auto;
}

.tierGrid table {
    width: 100%;
    font-size: 18px;
    border: 0px;
}

.tierGrid table tbody td {
    padding: 13px 15px 10px;
    text-align: center;
    color: #333;
    border: 0px;
    width: 33.33%;
    table-layout: fixed;
}

.tierGrid table tbody td strong {
    display: block;
    font-size: 20px;
    padding-bottom: 5px;
}

.tierGrid table tbody td ul,
.tierGrid table tbody td ul li {
    margin-top: 5px;
}

.tierGrid table tbody td a {
    display: inline-block;
    color: #fff;
    padding: 10px 20px 6px;
    border-radius: 20px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
}

.tierGrid table tbody tr:last-child td {
    vertical-align: middle;
}

.LTE_wrap {
    margin: 50px 0;
}

.LTE_wrap h2 {
    font-size: 24px;
}

.LTE_wrap_grid {
    width: 100%;
    overflow: auto;
    margin: 30px 0;
}

.LTE_wrap_grid table {
    width: 100%;
    font-size: 12px;
    border: 0;
}

.LTE_wrap_grid table th {
    border: 1px solid #fff;
    background-color: #6b6c6b;
    font-size: 20px;
    line-height: 40px;
    color: #fff;
    font-family: "ATTAleckSans-Regular";
    padding: 5px;
    vertical-align: middle;
    text-align: center;
}

.LTE_wrap_grid table tr td {
    border: 0;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    color: #333333;
    padding: 10px 5px;
    line-height: 25px;
}

.LTE_wrap_grid table tr td a {
    color: #2c8cb9;
    text-decoration: underline;
}

.LTE_wrap_grid table tr:nth-child(odd) {
    background-color: #f8f8f8;
}

.LTE_wrap_grid table tr:first-child td:nth-child(1) {
    background: #fff;
    border: 1px solid #fff;
}

.LTE_wrap_grid table tr:first-child td:nth-child(2) {
    background: #067fba;
    color: #fff;
    border: 1px solid #fff;
}

.LTE_wrap_grid table tr:first-child td {
    background: #05b2e0;
    color: #fff;
    border: 1px solid #fff;
}

.modal a[target="_blank"] {
    position: relative;
    padding-right: 14px;
}

.modal a[target="_blank"]:after {
    content: "";
    content: "";
    width: 10px;
    height: 10px;
    position: relative;
    display: inline-block;

    background-position: -12px 0px;
    margin-left: 4px;
    background-size: auto 9px;
    margin-top: -17px;
}

/* IoT Open Labs */
.openlabs_banner {
    height: 400px;
    background: rgba(0, 0, 0, 0.7)
        url("https://testlabhub.com/images/openlabs_banner.jpg") no-repeat
        scroll center;
    background-position: center;
    background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
}

.openlabs_banner .container {
    position: relative;
    height: 100%;
}

.openlabs_banner .banner_wrap .ban_content > div {
    background-color: rgba(255, 255, 255, 0.9);
}

.openlabs_banner p {
    font-size: 16px;
    color: #000;
}

.openlabs_banner h1 {
    color: #000;
}

.openlabs_wrap {
}

.openlabs_wrap p {
    margin-bottom: 20px;
}

.openlab_map {
    max-width: 989px;
    margin: auto;
    padding: 50px 0 0 0;
}

.openlab_map img {
    width: 100%;
}

.openlabs_form {
    max-width: 800px;
    margin: auto;
    margin-top: 50px;
}

.openlabs_form ul {
    margin: 0;
}

.openlabs_form ul li {
    padding: 0 15px;
}

.iotacc_home {
    text-align: center;
    display: block;
}

.iotacc_home img {
    width: auto !important;
}

/* //Start 20-MAR-2018  TKT 46 - CR - Update homepage Tiles to Slider announcement slider // Start 18-june-2018 #54 - Scrolling Tiles*/

.owl-carousel button.owl-prev,
.owl-carousel button.owl-next {
    color: #0568ae;
    background: none;
    display: inline-block;
    font-family: "icoPrimary";
    font-style: normal;
    font-size: 30px;
    padding: 5px;
    font-weight: normal;
    font-variant: normal;
    margin-right: 7px;
    text-transform: none;
    line-height: 1;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: none;
    position: relative;
    speak: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
}

.owl-carousel button.owl-prev {
    transform: rotate(90deg);
    left: -2%;
    position: absolute;
    top: 50%;
}

.owl-carousel button.owl-prev:before {
    content: "\e91c";
}

.owl-carousel button.owl-next {
    transform: rotate(-90deg);
    right: -2%;
    position: absolute;
    top: 50%;
}

.owl-carousel button.owl-next:before {
    content: "\e91c";
}

.owl-carousel button span {
    display: none;
}

.owl-carousel .owl-dots {
    display: none;
}

/* #47 */
ul.widicons_list_sub {
    padding-left: 20px;
    margin-top: 20px;
}

ul.widicons_list_sub li {
    list-style: outside;
}

.implement_wrap {
    position: relative;
    padding: 20px 0;
}

.implement_wrap:before {
    content: "";
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    left: 0;
    background-image: url("https://testlabhub.com/images/submitrfq/get-certified-listthumbs.png");
    background-repeat: no-repeat;
    background-position: 0 0;
}

.implement_wrap.guide_icon:before {
    background-position: -7px -2200px;
}

.implement_wrap.securityIcon:before {
    background-position: -7px -1012px;
}

.implement_wrap h2,
.implement_wrap p {
    padding-left: 95px;
}

.implement_wrap h2 {
    padding-bottom: 20px;
}

.implement_wrap.txtwhite {
    color: #fff;
}

.implement_wrap.txtwhite a {
    color: #fff;
    text-decoration: underline;
}

.id_sim {
    position: absolute;
    right: -80px;
    top: 0;
}

.id_sim1 {
    margin-top: -20px;
}

.id_sim2 {
    position: absolute;
    right: 10px;
    top: 40px;
}

.id_sim1 img,
.id_sim2 img {
    width: auto !important;
}

.left_col {
    float: left;
}

.right_col {
    float: left;
    padding-left: 20px;
}

.process_box {
    background-color: #5499cb;
    width: 280px;
    padding: 20px 10px;
    color: #fff;
    text-align: center;
    margin-top: 70px;
    font-size: 15px;
}

.process_box img {
    margin: auto;
    width: 140px !important;
    margin-top: -80px;
}

.process_box .fs28 {
    font-family: "ATTAleckSans-Medium";
}

.process_box span.fs28:last-of-type {
    background: #fff;
    width: 90%;
    margin: auto;
}

.process_box span {
    display: block;
}

.net_pad {
    padding-right: 190px;
    margin-bottom: 20px;
}

.net_pad p {
    padding-bottom: 20px;
}

.net_pad p:last-of-type {
    padding-bottom: 0px;
}

.iotAcltrWrap {
}

.iotAcltrWrap > img {
    max-width: 260px;
    height: auto;
}

.iotAcltrWrap > div {
    padding: 10px;
    background-color: #fdb735;
}

.iotAcltrWrap > div .fs18 {
    font-family: "ATTAleckSans-Bold";
}

.iotAcltrWrap > div p {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}

/* #51 - OTHER: Devices page update */
.zigzag_wrap.dataflowwrap {
    padding: 20px 0;
}

.dataflowwrap h2 {
    color: #2868a9;
    font-size: 40px;
    margin-bottom: 20px;
    margin-top: 50px;
}

.dataflowwrap .imgry_wrap {
    width: 742px;
}

.dataflowwrap .info_wrap {
    padding-right: 50px;
}

.dataflowwrap .imgry_wrap a[target="_blank"]:after {
    background: none;
}

/* BEGIN BUTTONS.LESS *************************** */
.btn {
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 100px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-family: "ATTAleckSans-Regular";
    font-size: 1.9rem;
    font-weight: normal;
    height: 46px;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 0;
    margin: 0 7px 0 0;
    max-width: 450px;
    min-width: 90px;
    padding: 0 19px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
}

.btn:last-child {
    margin-right: 0;
}

.btn::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a.btn {
    vertical-align: middle;
}

a.btn:focus,
a.btn:hover {
    text-decoration: none;
}

.btn-medium {
    height: 42px;
    font-size: 17px;
    /*padding:21px 18px 20px 18px;*/
}

.btn-small {
    height: 36px;
    font-size: 1.5rem;
    /*padding: 18px 18px 17px 18px;*/
}

.btn-fullwidth {
    width: 100%;
}

.btn-clear {
    outline-color: transparent !important;
    background: transparent !important;
    border-color: transparent !important;
    font-weight: normal;
    box-shadow: none;
    text-decoration: none;
    color: #0568ae;
}

.cta-button-group {
    -ms-flex-align: baseline;
    align-items: baseline;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20viewport%3D%220%200%201%201%22%20style%3D%22width%3A100%25%3B%20height%3A1px%3B%22%3E%3Cstyle%3Eline%7Bstroke%3A%23959595%3BstrokeWidth%3A2%3B%7D%3C%2Fstyle%3E%3Cline%20y2%3D%221%22%20y1%3D%221%22%20x1%3D%221%22%20x2%3D%22100%25%22%20stroke-dasharray%3D%221%2C%203%22%2F%3E%3C%2Fsvg%3E") !important;
    background-position: 0 -1px;
    background-repeat: repeat-x;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 20px 0 20px;
    width: 100%;
}

.cta-button-group .btn {
    margin-right: 0;
}

.cta-button-group .btn + .btn {
    margin-right: 20px;
}

/* .modal-docked .modal-footer .cta-button-group {
    background-image: none !important;
} */

.btn-primary-promo {
    background-color: #d16500;
    background-image: linear-gradient(to bottom, #ea7400 0%, #d16500 100%);
    color: #ffffff;
}

.btn-primary-promo:hover,
.btn-primary-promo:active {
    text-decoration: none;
    color: #ffffff;
    background: #c96100;
    border-color: #c96100;
}

.btn-primary-promo:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #c96100, inset 0 0 0 3px #fff,
        0 5px 5px -5px rgba(0, 0, 0, 0.15);
}

.btn-secondary {
    background-color: #f2f2f2;
    background-image: linear-gradient(to bottom, #fcfcfc 0%, #f2f2f2 100%);
    box-shadow: inset 0 0 0 1px #d2d2d2, 0 5px 5px -5px rgba(0, 0, 0, 0.15);
    color: #0568ae;
}

.btn-secondary:hover,
.btn-secondary:active {
    color: #0568ae;
    background: #f0f0f0;
}

.btn-secondary:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px #d2d2d2, inset 0 0 0 3px #fff,
        inset 0 0 0 4px #0568ae, 0 5px 5px -5px rgba(0, 0, 0, 0.15);
}

.btn-primary-functional {
    background-color: #0568ae;
    background-image: linear-gradient(to bottom, #087ac2 0%, #0568ae 100%);
    border-color: #087ac2 #087ac2 #0568ae #087ac2;
    color: #ffffff;
}

.btn-primary-functional:hover,
.btn-primary-functional:active {
    color: #ffffff;
    outline-color: #ffffff !important;
    background: #0563a6;
    border-color: #0563a6;
}

.btn-primary-functional:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #0563a6, inset 0 0 0 3px #fff,
        0 5px 5px -5px rgba(0, 0, 0, 0.15);
}

/* #56 - Need LOE for LTE-M Module Comparison Grid */
.module_compare_tile {
    padding: 0px 0 10px 0;
}

/* 13-APR-20 Fixed Module Tiles Alignment */
/*.module_compare_tile .tile_row{ margin:0 -15px; }*/
.module_compare_tile .tile_row {
    margin: 0 -15px;
    font-size: 0px;
}

.tile_row:after,
.tile_row:before {
    content: " ";
    display: table;
}

.tile_row:after {
    clear: both;
}

/* 13-APR-20 Fixed Module Tiles Alignment */
/*.column_block{ padding:15px; float:left; width:25%;}*/
.column_block {
    padding: 15px;
    width: 25%;
    display: inline-block;
    vertical-align: top;
}

.column_block .pro_wrap {
    border: 3px solid #4bb0dc;
}

.column_block .pro_wrap > div {
    text-align: center;
}

.column_block .pro_wrap .pro_img {
    padding: 10px 0 0;
}

.column_block .pro_wrap .pro_img > img {
    margin-right: auto;
    margin-left: auto;
    display: block;
    height: auto;
    max-width: 70%;
}

.column_block .pro_wrap h3 {
    background-color: #4bb0dc;
    font-family: "ATTAleckSans-Medium";
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    padding: 8px 10px 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.column_block .pro_wrap .pro_detail {
    display: table;
    width: 100%;
    padding: 10px;
}

.column_block .pro_wrap .pro_detail .benefit {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    min-height: 100px;
}

.column_block .pro_wrap .pro_detail .price {
    font-family: "ATTAleckSans-Bold";
    font-size: 30px;
    min-height: 60px;
}

.column_block .pro_wrap .pro_detail .price span {
    display: block;
    font-size: 14px;
    font-family: "ATTAleckSans-Regular";
}

.column_block .pro_wrap .pro_detail .price.small {
    font-size: 18px;
    min-height: 45px;
}

.column_block .pro_wrap .action_wrap {
    background-color: #4bb0dc;
    padding: 20px 20px;
}

.column_block .pro_wrap .action_wrap a {
    display: block;
    color: #fff;
    font-size: 16px;
    text-decoration: underline;
    margin-bottom: 20px;
}

.column_block .pro_wrap .action_wrap button {
    border: 0;
    background-color: #fff;
    padding: 7px 20px 5px;
    display: block;
    width: 100%;
    border-radius: 8px;
    color: #4bb0dc;
    font-size: 20px;
    text-align: center;
}

/* #56 - End */

/* Begin : #68 - CR ETKT #3: Partner Page */
.partnerPage {
    overflow: auto;
    padding: 0px 0px 50px;
}

.chekFilterWrap {
    padding: 40px 0px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 40px;
}

.partnerPage .fs24 {
    padding: 0px 0px 10px;
}

.chekFilterWrap .checkboxWrap {
    display: inline-block;
    vertical-align: top;
    width: 290px;
}

.checkboxWrap .tooltip {
    margin-bottom: 10px;
}

label,
input[type="radio"],
input[type="checkbox"] {
    cursor: pointer;
}

.checkbox,
.radio {
    position: relative;
    min-height: 24px;
    font-family: "ATTAleckSans-Regular";
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.checkbox.inline,
.radio.inline {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: middle;
    line-height: 2rem;
}

.checkbox input,
.radio input {
    -webkit-tap-highlight-color: transparent;
    height: 24px;
    margin: 0;
    padding: 0;
    opacity: 0;
    outline: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    z-index: 1;
}

.checkbox .skin,
.radio .skin {
    background-color: #fff;
    border: 1px solid #959595;
    border-radius: 3px;
    display: inline-block;
    height: 24px;
    left: 0;
    position: absolute;
    margin-right: 10px;
    top: 0;
    width: 24px;
    float: left;
}

.checkbox span,
.radio span {
    display: inline-block;
    margin-top: 0;
    margin-left: 34px;
    position: relative;
    top: 1px;
}

.checkbox input:checked + .skin:after,
.icon-tooltip {
    font-family: "icoPrimary" !important;
    height: 24px;
    width: 24px;
    display: inline-block;
    background-color: transparent;
    font-size: 20px;
    color: #fff;
    line-height: 20px;
    vertical-align: top;
}

.checkbox input:checked:not(:disabled) + .skin {
    background-color: #0568ae;
    border-color: #0568ae;
}

.checkbox input:checked + .skin:after {
    content: "\e921";
}

.icon-tooltip:before,
.icon-flat-faq:before {
    content: "\e923";
}

ul.partnerList {
    text-align: center;
    margin: 20px 0px;
}

ul.partnerList li {
    width: 285px;
    height: 200px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 10px 2px;
    cursor: default;
    overflow: hidden;
}

ul.partnerList li .thumbWrap {
    display: table;
    width: 100%;
    height: 100%;
}

ul.partnerList li .thumbWrap img {
    max-width: 260px;
    max-height: 160px;
}

ul.partnerList li .thumbWrap > div {
    display: table-cell;
    vertical-align: middle;
}

ul.partnerList li .infoWrap {
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 100%;
    height: 100%;
    background-color: rgba(241, 241, 241, 0.9);
    position: absolute;
    left: 0;
    top: 100%;
    padding: 20px;
    text-align: left;
    overflow: hidden;
}

ul.partnerList li .infoWrap strong {
    font-size: 16px;
    display: block;
}

ul.partnerList li:hover .infoWrap {
    top: 0;
}

/*Partners Page - Harish*/
/* BEGIN Tooltip - Harish***************************** */
.icon-tooltip {
    display: inline-block;
    height: 20px;
    margin: 0px 0px 10px 5px;
    vertical-align: middle;
    font-size: 20px;
    position: relative;
    text-decoration: none !important;
}

.form-row label .icon-tooltip {
    margin: -6px 3px 0px 3px;
}

#tooltip {
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    background: #0568ae;
    position: absolute;
    z-index: 1042;
    padding: 15px;
    border-radius: 5px;
}

#tooltip:after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #0568ae;
    content: "";
    position: absolute;
    left: 50%;
    bottom: -10px;
    margin-left: -10px;
}

#tooltip.top:after {
    border-top-color: transparent;
    border-bottom: 10px solid #0568ae;
    top: -20px;
    bottom: auto;
}

#tooltip.left:after {
    left: 10px;
    margin: 0;
}

#tooltip.right:after {
    right: 10px;
    left: auto;
    margin: 0;
}

/* END #68 - CR ETKT #3: Partner Page */

.newDevices .aclrtBttns {
    padding: 30px 0px;
}

.newDevices .aclrtBttns a {
    background-color: #0568ae;
}

.newDevices .aclrtBttns a:last-of-type {
    background-color: #ffb81c;
}

.newDevices .zigzag_wrap {
    padding: 20px 0px;
}

.newDevices .zigzag_wrap .imgry_wrap img {
    width: 90%;
}

.newDevices.cjChart .zigzag_wrap {
    padding: 50px 0px;
}

.deviceThumbR {
    float: left;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    background-image: url("https://testlabhub.com/rfq/icon/deviceSprite.png");
    background-repeat: no-repeat;
    background-position: -1px -1px;
}

.thumb1 {
    margin-top: -5px;
}

.thumb2 {
    background-position: -49px -1px;
}

.thumb3 {
    background-position: -49px -65px;
    height: 25px;
}

.thumb4 {
    background-position: -1px -50px;
    margin-top: -5px;
}

.thumb5 {
    background-position: -1px -100px;
    margin-top: 15px;
}

.thumb6 {
    background-position: -49px -100px;
    margin-top: -5px;
}

.deviceThumbR + h2,
.thumb5 + ul,
.thumb2 + p {
    overflow: hidden;
}

@media all and (max-width: 840px) {
    .footul_wrap > div {
        display: block;
        min-height: auto;
    }

    .footul_wrap ul li a {
        width: auto;
    }
}

@media all and (max-width: 768px) {
    .legalnbadge_wrap {
        padding-right: 0px;
    }

    .badge_wrap {
        position: relative;
        bottom: auto;
        margin-top: 20px;
    }

    .aclrtBttns {
        text-align: center;
    }

    .aclrtBttns a {
        margin: 10px;
    }
}

@media all and (max-width: 580px) {
    .footul_wrap {
        float: none;
    }

    .footcnnect_wrap {
        padding: 20px 0px 20px;
        border-left: 0px;
        float: none;
        border-top: 3px solid #333;
        border-bottom: 3px solid #333;
        margin-top: 20px;
    }

    .form-within-process .row-nowrap .span6 {
        width: 100%;
        float: none;
        margin: 0px;
        display: block;
    }

    .row,
    .row-nowrap {
        display: block;
    }

    .form-within-process .form-row {
        padding: 0px 0px 10px;
    }
}

@media all and (max-width: 1280px) {
    .contact_banner,
    .netwrk_readybanner,
    .modulePagebanner,
    .devicePagebanner,
    .networkCertified_banner {
        height: 310px;
    }

    .zigzag_wrap,
    .why_certify_section .fs24,
    .why_certify_section p,
    .take_advntg_section,
    .m2xflow,
    .completentwk_wrap,
    .iotaccelerator_wrap {
        padding-left: 4%;
        padding-right: 4%;
    }

    .iotaccePagebanner {
        background-position: 95% center;
    }

    .netwrk_ready .zigzag_wrap .imgry_wrap {
        padding-left: 50px;
    }
}

@media all and (min-width: 1025px) {
    ul.anncment_list {
    }

    ul.anncment_list li {
        float: none;
        display: table-cell;
    }

    .cd-search input {
        padding: 0 0.5em;
        font-size: 3.2rem;
        color: #666;
    }

    .cd-search {
        height: 100px;
        top: 53px;
    }

    .cjc_chart h2 {
        margin-left: 240px;
    }
}

@media all and (max-width: 1024px) {
    ul.anncment_list li {
        width: 92%;
    }

    /* .footer .container {
    padding-left: 15px;
    padding-right: 15px;
} */
    .mail_list li {
        width: 50%;
    }

    .dataflowwrap .info_wrap {
        padding-right: 0px;
    }

    .column_block {
        width: 33.33%;
    }
}

@media all and (max-width: 1067px) {
    .GetStarted_wrap .GetStartedInfo {
        width: 100%;
        padding: 5% 10% 0 10%;
    }

    .GetStarted_wrap .GetStartedPic {
        width: 50%;
        margin: 0 25% -10px 25%;
    }

    .devices_wrap {
        padding: 40px 0px 30px;
        width: 95%;
        margin: auto;
    }
}

@media all and (max-width: 1024px) and (min-width: 641px) {
    ul.anncment_list li a p {
        min-height: 185px;
    }
}

@media all and (max-width: 1170px) and (min-width: 768px) {
    .devicebuy_wrap,
    .devicebuild_wrap {
        height: 650px;
    }
}

@media all and (max-width: 1024px) and (min-width: 681px) {
    .topright_wrap {
        clear: both;
        float: none;
    }

    /* {border-top:1px solid #ccc;}
, ul{width:100%;margin:0 auto;background-color:#595959;}
, ul{text-align:justify;}
 ul{display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;}
 ul li a{padding:0px 18px;} ul li.active a, ul li a:hover{opacity:0.8;} */

    .global-search {
        margin-top: -39px;
        margin-right: 11px;
    }
}

@media all and (max-width: 980px) {
    .netwrk_ready .zigzag_wrap .imgry_wrap:nth-child(even) {
        padding-top: 20px;
    }

    .netwrk_ready .zigzag_wrap .imgry_wrap:nth-child(odd) {
        padding-bottom: 20px;
    }

    .zigzag_wrap .imgry_wrap,
    .zigzag_wrap .info_wrap,
    .netwrk_ready .zigzag_wrap .imgry_wrap,
    .netwrk_ready .zigzag_wrap .info_wrap,
    .zigzag_wrap.abtiot_wrap .imgry_wrap,
    .zigzag_wrap.wireles_comnctn_wrap .imgry_wrap,
    .why_certify_section .fs24,
    .why_certify_section p,
    .take_advntg_section,
    .m2xflow,
    .network-chart {
        display: block;
        width: 95%;
        margin: auto;
    }

    .zigzag_wrap.abtiot_wrap .imgry_wrap,
    .zigzag_wrap.wireles_comnctn_wrap .imgry_wrap {
        padding-bottom: 20px;
    }

    .zigzag_wrap.gt_crtfied_wrap .imgry_wrap {
        padding-top: 20px;
    }

    .zigzag_wrap img {
        width: 55%;
    }

    .zigzag_wrap.abtiot_wrap img,
    .zigzag_wrap.wireles_comnctn_wrap img {
        width: 70%;
    }

    .hide_sml {
        display: none !important;
    }

    .bx_wrap {
        width: 100%;
        float: none;
        min-height: 200px;
    }

    .twinbx_wrap {
        width: 85%;
    }

    .twinbx_wrap .bx_wrap:last-child {
        float: none;
        margin-top: 45px;
    }

    .completentwk_wrap .devicewrap,
    .completentwk_wrap .runtest {
        width: 47%;
    }

    .net_pad {
        padding: 0;
    }

    .dataflowwrap img {
        width: 90%;
    }

    /*#51 - OTHER: Devices page update*/
    .dataflowwrap h2 {
        margin-top: 0px;
    }

    .newDevices .zigzag_wrap .imgry_wrap {
        padding: 20px 0px;
    }
}

@media all and (min-width: 981px) {
    .footlink_wrap .social_wrap {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .footlink_wrap .social_wrap a {
        margin: 0px 0px 0px 10px;
    }

    .netwrk_ready .zigzag_wrap .imgry_wrap:nth-child(even) {
        text-align: right;
    }

    .netwrk_ready .zigzag_wrap .imgry_wrap:nth-child(odd) {
        text-align: left;
    }

    .zigzag_wrap img {
        width: 80%;
    }

    .netwrk_ready .zigzag_wrap.someDevices_wrap .imgry_wrap {
        width: 50%;
    }

    .zigzag_wrap.abtiot_wrap .imgry_wrap,
    .zigzag_wrap.wireles_comnctn_wrap .imgry_wrap {
        text-align: left;
    }

    /* .comingsoonfooter + footer.footer{position:absolute;bottom:0; width:100%;} */

    .newDevices .zigzag_wrap:nth-child(odd) .imgry_wrap {
        float: left;
        text-align: left;
    }

    .newDevices .zigzag_wrap:nth-child(even) .imgry_wrap {
        float: right;
        text-align: right;
    }

    .newDevices .zigzag_wrap .imgry_wrap {
        width: 305px;
    }

    .newDevices.cjChart .zigzag_wrap .imgry_wrap {
        width: 370px;
    }

    .newDevices.cjChart ul.widicons_list_sub {
        margin: 10px 0px 20px;
    }
}

@media all and (max-width: 768px) {
    .attiot_wrap {
        padding: 60px 0px 60px;
    }

    .attiot_wrap .left,
    .attiot_wrap .right {
        width: 100%;
        display: block;
    }

    .attiot_wrap .left img {
        max-width: 360px !important;
        width: 100%;
    }

    .attiot_wrap .right {
        padding: 30px 0px 0px;
    }

    .attiot_box {
        max-width: 500px;
    }

    .images_section .imgtwo_col .colmn {
        width: 100%;
        display: block;
    }

    .images_section .imgtwo_col .colmn:last-child {
        padding-top: 0px;
    }

    .center_cntnt_wrap {
        display: block;
    }

    .blue_strip {
        font-size: 25px;
        line-height: 34px;
        padding: 42px 20px 38px;
    }

    .blue_strip strong {
        font-size: 32px;
    }

    .container .blue_strip {
        padding: 25px 20px 21px;
        font-size: 22px;
        line-height: 30px;
    }

    .announcements_wrap .fs22 {
        font-size: 22px;
    }

    .completentwk_wrap .devicewrap,
    .completentwk_wrap .runtest {
        width: 100%;
    }

    .completentwk_wrap .devicewrap .diagram {
        margin: auto;
    }

    .completentwk_wrap .devicewrap {
        margin-bottom: 20px;
    }

    .content_right_wrap {
        float: right;
        width: 100%;
        padding-left: 0;
    }

    .threeblock_wrap .column {
        width: 50%;
    }

    .broadband_wrap_grid .device_list div li {
        display: block;
    }

    .broadband_wrap_grid .device_list div li,
    .broadband_wrap_grid table tr td:nth-child(1),
    .broadband_wrap_grid table tr td:nth-child(2),
    .broadband_wrap_grid table th,
    .LTE_wrap_grid table tr td,
    .LTE_wrap_grid table tr th {
        font-size: 16px;
    }

    .LTE_wrap_grid table tr td,
    .LTE_wrap_grid table tr th {
        font-size: 16px;
        line-height: 24px;
    }

    .iotaccePagebanner {
        background-position: 20% center;
    }

    .blue_strip .bheadtwocol {
        float: none;
        width: 100%;
        text-align: center;
        display: block;
        padding-bottom: 30px;
        border-right: 0;
        padding-right: 0;
    }

    .blue_strip .bheadtwocol:last-child {
        padding-bottom: 0;
        border-right: 0;
        padding-top: 30px;
        padding-left: 0px;
        border-top: 1px solid #fff;
    }

    .blue_strip .bheadThreeCols {
        max-width: 500px;
        margin: auto;
        line-height: 24px;
    }

    .blue_strip .bheadThreeCols .bheadCol {
        display: block;
        text-align: center;
        width: 100%;
        padding: 35px 15%;
        border: 0px;
        border-top: 1px solid #fff;
    }

    .blue_strip .bheadThreeCols .bheadCol:first-child {
        border-top: 0px;
    }

    .blue_strip .bheadThreeCols .bheadCol:first-child,
    .blue_strip .bheadThreeCols .bheadCol:last-child {
        padding: 35px 15%;
    }

    .column_block {
        width: 50%;
    }

    /* #56 - Need LOE for LTE-M Module Comparison Grid */
}

@media all and (max-width: 680px) {
    .contact_wrap .spacer {
        padding: 9% 0px;
    }

    .toggle {
        display: block;
        cursor: pointer;
        background: none;
        border: 0px;
        width: 50px;
        text-indent: -9999em;
        position: relative;
        padding: 0px;
        margin: 6px;
    }

    .toggle:before {
        content: "";
        width: 40px;
        height: 30px;
        right: 5px;
        top: 5px;
        background-image: url("https://testlabhub.com/images/hamburger-icon.png");
        background-size: 65px;
        background-position: 3px 4px;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
    }

    .toggle.show:before {
        background-position: -34px 4px;
    }

    /* ul {
        display: none;
        border-top: 1px solid #000;
        border-bottom: 3px solid #000;
        background-color: #595959;
        position: absolute;
        right: 10px;
        width: 200px;
        z-index: 1;
        margin-top: 1px;
    } */

    ul li a {
        padding: 4px 12px;
        color: #fff;
        font-size: 16px;
    }

    ul li a:hover,
    ul li.active a {
        color: #fff;
        background-color: #126aac;
    }

    ul li {
        float: none;
        display: block;
        /* border-top: 1px solid #494949; */
    }

    .banner_wrap {
        width: 100%;
    }

    .cnterbanner_headings .fs28 {
        font-size: 23px;
    }

    .cnterbanner_headings p {
        text-align: center;
    }

    .cnterNetwork_headings .fs28 {
        font-size: 23px;
        color: black;
    }

    .cnterNetwork_headings p {
        text-align: center;
        color: black;
    }

    .global-search {
        float: none;
        position: absolute;
        width: 96%;
        margin-left: 2%;
        margin-right: 2%;
        left: 0;
        top: 45px;
    }
}

@media all and (max-width: 640px) {
    ul.anncment_list li {
        width: 100%;
    }

    .contact_wrap .form_wrap .col-6,
    .openlabs_form .col-6 {
        width: 100%;
    }

    .cnterbanner_headings .fs28 {
        font-size: 24px;
    }

    .cnterbanner_headings p {
        font-size: 15px;
    }

    .cnterNetwork_headings.fs28 {
        font-size: 24px;
        color: black;
    }

    .cnterbanner_headings p {
        font-size: 15px;
        color: black;
    }

    .images_section .fs18 {
        padding-bottom: 8px;
    }

    .coming_wrap .text_block {
        height: auto;
    }

    .contact_banner.acclrtd {
        background-position: right center;
    }
}

@media all and (max-width: 520px) {
    .zigzag_wrap .fs24 {
        text-align:
      /*center*/ left;
    }

    ul.widicons_list {
    }

    ul.widicons_list > li {
        text-align:
      /*center*/ left;
        padding: 25px 0px 10px;
    }

    ul.widicons_list > li:before {
        position: relative;
        margin: auto;
        margin-bottom: 15px;
    }

    ul.widicons_list.wid_or {
        padding: 0px;
    }

    ul.widicons_list.wid_or li {
        padding: 35px 10px;
    }

    ul.widicons_list.wid_or li:before {
        margin-bottom: 5px;
    }

    .securityIcon_wrap {
        text-align: center;
        padding: 10px 10px 30px;
    }

    .securityIcon_wrap:before {
        position: relative;
        margin: auto;
        margin-bottom: 15px;
    }

    ul.points {
        margin-left: 50px;
    }

    footer .foot_link1 li {
        display: block;
        padding: 10px 0 !important;
        position: relative;
        text-align: center;
    }

    footer .foot_link1 li a {
        display: block;
        text-align: center;
    }

    footer .foot_link1 li a img {
        display: inline-block;
        position: relative;
        top: 0;
    }

    footer .foot_link1 li a[target="_blank"]:after {
        position: relative;
        bottom: 6px;
        margin-left: 4px;
    }

    .lk {
        display: inline-block;
        text-align: left;
    }

    footer .foot_link1 li::after {
        display: none;
    }

    .netwrk_readybanner {
        padding: 2% 0px;
    }

    .networkCertified_banner {
        padding: 2% 0px;
    }

    .devices_wrap .fs28 {
        padding-left: 0;
    }

    ul.devices_list {
        text-align: center;
    }

    ul.devices_list > li {
        padding: 25px 0px 25px;
    }

    ul.devices_list > li:before {
        position: relative;
        left: 50%;
        margin-left: -35px;
    }

    .twinbx_wrap {
        margin: 30px auto 50px;
    }

    .bx_wrap {
        text-align: left;
    }

    .devicePagebanner .banner_wrap {
        width: 90%;
    }

    .threeblock_wrap .column {
        width: 100%;
    }

    .column_block {
        width: 100%;
    }

    /* #56 - Need LOE for LTE-M Module Comparison Grid */
}

@media all and (max-width: 480px) {
    .logo {
        float: left;
        padding: 10px 8px;
    }

    .logo a img {
        width: 26px;
        height: auto;
    }

    .logo a:last-child {
        margin: 0px 0px 0px 10px;
        padding: 3px 2px 2px;
        font-size: 14px;
        font-family: "ATTAleckSans-Bold";
    }

    .logo a:last-child::before {
        left: -5px;
        height: 15px;
        top: 5px;
    }

    .toggle {
        width: 40px;
        margin: 3px 0px;
    }

    .toggle:before {
        width: 30px;
        background-size: 50px;
        background-position: 2px 6px;
    }

    .toggle.show:before {
        background-position: -26px 6px;
    }

    .twinbx_wrap {
        margin: 30px -15px 50px;
        width: auto;
    }

    .cd-pagination {
        display: block;
        max-width: 272px;
        margin: 1em auto 7em;
    }

    .cd-pagination.move-buttons .button:first-of-type,
    .cd-pagination.move-buttons .button:last-of-type {
        position: absolute;
        top: 40px;
    }

    .cd-pagination.move-buttons .button:first-of-type {
        left: 0;
    }

    .cd-pagination.move-buttons .button:last-of-type {
        right: 0;
    }

    .basicbanner {
        height: auto;
    }

    .cjc_chart h2 {
        font-size: 14px;
    }

    .cjc_chart h2 span {
        font-size: 20px;
    }

    .mail_list li {
        width: 100%;
    }

    .completentwk_wrap .devicewrap .diagram .cell {
        width: 49%;
        margin-top: 30px;
    }

    .sms_wrap {
        width: 100%;
        padding-left: 63px;
    }

    .completentwk_wrap .devicewrap .diagram .cell div:nth-child(5),
    .threeblock_wrap .column div h4 {
        font-size: 13px;
    }

    .LTE_wrap_grid table tr td,
    .LTE_wrap_grid table tr th {
        font-size: 13px;
        line-height: 14px;
    }

    .iotaccePagebanner {
        height: auto;
    }

    .owl-buttons {
        display: none;
    }

    .alterSpan {
        margin-bottom: 20px;
        display: block;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
}

/*added by sudhir #47*/
@media all and (max-width: 980px) {
    .netwrk_ready .zigzag_wrap .imgry_wrap {
        padding-left: 0px;
    }

    .netwrk_ready .zigzag_wrap img {
        width: auto;
    }

    .netwrk_ready .process_box {
        width: auto;
    }

    .netwrk_ready .net_pad {
        padding-right: 200px;
    }

    .id_sim {
        right: -30px;
        top: 10px;
    }
}

@media all and (max-width: 990px) {
    .netwrk_ready .right_col {
        padding-left: 15px;
    }
}

@media all and (max-width: 660px) {
    .netwrk_ready .right_col,
    .netwrk_ready .left_col {
        float: none;
    }

    .netwrk_ready .right_col {
        padding-left: 0px;
        padding-top: 40px;
    }

    .netwrk_ready .net_pad {
        padding-right: 0px;
    }

    .id_sim,
    .id_sim2 {
        right: 0px;
        position: relative;
        top: 10px;
        display: block;
    }
}

@media all and (max-width: 520px) {
    .id_sim2,
    .id_sim1,
    .id_sim {
        text-align: center;
    }
}

/* Begin  #70 - CR ETKT #4: Add html pages to Microsite  */

/* End  #70 - CR ETKT #4: Add html pages to Microsite  */

/* Acce contact css */
.brd {
    border-color: red !important;
}

.validationSp {
    display: none;
    color: red;
    padding: 2px 0px 0px;
}

.validationSp_recaptcha {
    display: none;
    color: red;
    padding: 2px 0px 0px;
}

.validationSp_module {
    display: none;
    color: red;
    padding: 2px 0px 0px;
}

.validationSp_quantity {
    display: none;
    color: red;
    padding: 2px 0px 0px;
}

.Staticbullet li {
    list-style-type: inherit;
}

/* Extra CSS For the Page Customization */
body {
    overflow-x: hidden;
}

h3,
.fs24 {
    color: #700203;
    font-size: 24px;
}

.zigzag_wrap {
    padding: 0px 0px;
}

.center_cntnt_wrap {
    text-align: center;
    background-size: cover;
    color: #fff;
    width: 580px;
    height: 300px;
    border: 2px solid;
}

p {
    font-size: 14px;
}
