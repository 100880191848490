.auth-buttons {
    float: right;
    margin: 20px;
}

.auth-buttons a {
    color: black;
    font-weight: bold;
    text-decoration: none;
    margin: 12px;
}

.modal-body a {
    text-decoration: none;
}

.auth-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid rgb(196, 195, 195);
}

.icon-container {
    margin-bottom: 10px;
}

.icon {
    width: 130px;
    height: 130px;
}

.steps-section {
    text-align: center;
    padding: 70px 20px;
    background-color: #fff;
}

.steps-section h2 {
    font-size: 32px;
    margin-bottom: 30px;
}

.steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.step {
    flex: 1 1 calc(33.333% - 30px);
    max-width: 400px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
}

.step img {
    width: 220px;
    height: 130px;
    margin-bottom: 20px;
}

.step h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.step p {
    font-size: 16px;
    color: #666;
}

@media (max-width: 768px) {
    .step {
        flex: 1 1 calc(50% - 30px);
    }
}

@media (max-width: 480px) {
    .step {
        flex: 1 1 calc(100% - 30px);
    }
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 20px;
}
.grid-item {
    position: relative;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}
.grid-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.grid-item img,
.grid-item video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
